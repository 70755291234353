$site-footer-bg: #2c3840;

.site-footer {
  background: url(../img/footer-shadow.png) no-repeat top center;
  background-color: $light-gray;
  //color: lighten($site-footer-bg, 40%);
  padding: 4rem 0 0;

  .site-footer-menu-social a {
    color: $black;
  }

  .column-block {
    margin-bottom: 30px;
  }

  > .row {
    margin-bottom: 1rem;
  }

  @include breakpoint(small only) {
    .columns {
      margin-bottom: 2rem;
    }
  }
}

.site-footer-primary {
  padding-bottom: 40px;

  p {
    font-size: 0.85rem;
  }
}

.site-footer-name {
  //color: $white;
  margin-bottom: 1rem;
  font-size: 2rem;
  text-align: center;

  img {
    width: 80%;
  }
}

.site-footer-title {
  //color: $white;
  margin-bottom: 1rem;
  font-size: 1.15rem;
  font-weight: 500;
}

.site-footer-block {
  margin-bottom: 1rem;

  .site-footer-subtitle {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .fa {
    font-size: 0.9rem;
    color: darken($site-footer-bg, 20%);
    margin-right: 0.8rem;
  }

  p {
    margin-left: 1rem;
    line-height: 1.125rem;
  }
}

.site-footer-menu-links {
  	margin-left: 1rem;
  	font-size: 0.85rem;
	list-style: disclosure-closed;

  	a {
    	color: $black;
    	line-height: 1.6;
    	padding: 0;

    	&:hover {
      	color: darken($primary-color, 35%);
    	}
  	}
}

.site-footer-menu-terms {
  	font-size: 0.6rem;

  	a {
    	line-height: 1.6;
    	padding: 0;
  	}
}

.site-footer-bottom {
  background: #333;
  padding: 1rem 0;

  p {
    color: #888;
    font-size: 0.75rem;
    margin-bottom: 0;
  }

  .menu {
    margin-bottom: 0;
  }

  .site-footer-bottom-links {
    justify-content: flex-end;

    a {
      color: lighten($site-footer-bg, 40%);
    }

    @include breakpoint(medium down) {
      justify-content: center;
    }
  }

  @include breakpoint(medium down) {
    text-align: center;
  }

  .juice-powered {
    text-align: right;
    
    @include breakpoint(medium down) {
      text-align: center;
    }

    .fa {
      margin-right: 0.4rem;
    }

  }
}