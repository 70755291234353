$app-dashboard-top-nav-height: rem-calc(55);

.app-dashboard-top-nav-bar  {
  height: $app-dashboard-top-nav-height;
  width: 100%;
  flex: 0 0 $app-dashboard-top-nav-height;
}

.main-nav {
   position: absolute;
   margin-top: 0.5rem;
   width: 100%;
   z-index: 1;

   .top-bar-title {

   	img {
      	width: 45%;
      }
   }
}

$hero-full-screen-bg: url('../img/gabriel-jimenez-150063-unsplash.jpg') center center no-repeat;
$hero-full-screen-bg-size: cover;
$hero-full-screen-text-color: $white;

.hero-full-screen {
   position: relative;
   height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
   //background: $hero-full-screen-bg;
   //background-size: $hero-full-screen-bg-size;

   &:before {
      display: block;
      position: absolute;
      background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0, #000 115%);
      height: 280px;
      width: 100%;
      content: '';
      z-index: 1;
   }

   .orbit {
      width: 100%;
   }

   .orbit-figure {
      height: 100vh;
   }
   .orbit-image {
      height: 100%;
      object-fit: cover;
   }

   .middle-content-section {
      position: absolute;
      bottom: 50%;
      transform: translateY(50%);
      text-align: center;
      color: $hero-full-screen-text-color;
      width: 50%;
      padding: 1rem;
      background-color: rgba(10, 10, 10, 0.5);
   }

   .top-content-section {
      width: 100%;
   }

   .bottom-content-section {
      position: absolute;
      padding: 1rem;
      bottom: 0;

      svg {
         height: rem-calc(60);
         width: rem-calc(60);
         fill: $hero-full-screen-text-color;
      }
   }

   // optional demo styles
   .top-bar {
      background: transparent;

      .menu {
         background: transparent;
      }

      .menu-text {
         color: $hero-full-screen-text-color;
      }

      .menu li {
         display: flex;
         align-items: center;
      }

      .menu a {
         color: $hero-full-screen-text-color;
         font-weight: bold;
      }
   }
}

.hero-half-screen {
   position: relative;
   height: 60vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
   background: $hero-full-screen-bg;
   background-size: $hero-full-screen-bg-size;

   &:before {
      display: block;
      position: absolute;
      background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0, #000 150%);
      height: 50vh;
      width: 100%;
      content: '';
      z-index: 1;
   }

   .middle-content-section {
      position: absolute;
      bottom: 10%;
      color: $white;
      width: 75%;
      padding: 2rem;
      z-index: 2;

      h1 {
      	font-weight: 600;
      	text-transform: uppercase;
      }
   }
}

.nav-container {
	position: absolute;
   margin-top: 0.5rem;
   width: 100%;
   z-index: 10;
}

.main-tpnav {
	background-color: transparent;

	.top-bar-title {

		img {
			width: 55%;
		}

		.tbt-lead {
			font-size: 0.7rem;
			margin-left: 0.9rem;
			margin-bottom: 0;
			color: $primary-color;
		}
	}

	.quick-menu {
		background: transparent;
		margin-right: 0.6rem;

		li {
			margin-right: 0.4rem;

			&:last-child {
				margin-right: 0;
			}
		}

		a {
			border: 2px solid $primary-color;
			border-radius: 20px;
			color: $primary-color;
			font-size: 0.8rem;
			padding: 0.4rem 1rem;
		}

		.fa {
			margin-right: 0.4rem;
		}
	}
}

.main-tpnav2 {

	.menu {

		.active > a {
			background-color: transparent;
			color: $primary-color;

		}

		.active > a:after {
			background-color: $primary-color;
			content: '';
			display: block;
			height: 1px;
			width: 100%;
    		transition: width .5s ease, background-color .5s ease;
		}

		a {
			color: $white;
			font-family: $head-font-family;
			font-weight: 600;
			text-transform: uppercase;		
			line-height: 1.8;
			transition: opacity .3s ease-in-out,color .3s ease-in-out;

			&:after {
				content: '';
			   display: block;
			   height: 1px;
			   width: 0px;
			   background-color: transparent;
			   transition: width .5s ease, background-color .5s ease;
			}

			&:hover {
				color: darken($white, 10%);
				opacity: 0.9;
			}

			&:hover:after {
		   	width: 100%;
				background-color: $primary-color;
			}
		}
	}
}