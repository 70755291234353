.bread-wrapper {
	background: $light-gray;
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;

	.breadcrumbs {
		margin: 0;
	}

	li {
		color: $dark-gray;
		font-size: 0.725rem;

		&:not(:last-child):after {
			font-family: FontAwesome;
			content: "\f0da";
		}
	}

	a {
		color: $dark-gray;

		&:hover {
			color: darken($primary-color, 25%);
		}
	}
}

.content-page {
	padding-top: 80px;
	padding-bottom: 80px;
}