.section-wrapper {
	padding-top: 90px;
	padding-bottom: 90px;
}

.section-header {
	position: relative;
  	margin-left: auto;
  	margin-right: auto;
  	margin-bottom: 40px;
  	z-index: 5;

  	h2 {
  		font-weight: 700;
  	}
}

.psg-row {
	max-width: 60rem;
}

.psg-block {
  	margin-bottom: 20px;

  	.psg-hover {
  		position: relative;
		padding: 0;
		overflow: hidden;
		background: #333333;
		text-align: center;
		cursor: pointer;

		&:hover {

			img {
				opacity: .5;
				transform: scale(1);
			}

			.psg-caption:before {
				opacity: 1;
			   transform: scale(1);
			}
		}
	}

  	img {
  		opacity: .8;
  		transition: opacity .35s,transform .35s;
  		transform: scale(1.12);
		width: 100%;
  	}

  	.psg-caption {
  		padding: 1rem;
  		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		&:before {
			position: absolute;
			top: 10px;
			right: 10px;
			bottom: 10px;
			left: 10px;
			border: 1px solid #fff;
			content: '';
			transition: opacity .35s,transform .35s;
			transform: scale(1.1);
			opacity: 0;
		}
  	}

  	.psg-block-title {
    	font-weight: 500;
    	margin-top: 1rem;
    	text-transform: uppercase;
    	letter-spacing: 1px;
    	font-size: 1em;
    	color: $white;
  	}
}


.latest-news-section {
	position: relative;


	&:before {
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.45);
		content: "";
		left: 0;
		top: 0;
	}

	&:after {
		background: url('../img/pexels-photo-935866.jpeg');
		background-size: cover;	
		content: "";
	  	top: 0;
	  	left: 0;
	  	bottom: 0;
	  	right: 0;
	  	position: absolute;
	  	z-index: -1;
	}

	.section-header {
		color: $white;
	}

}

.lng-block {
	position: relative;
  	margin-bottom: 20px;
  	z-index: 5;

  	.lng-hover {
  		position: relative;
		padding: 0;
		overflow: hidden;
		background: $white;
		cursor: pointer;
		min-height: 198px;

		&:hover {

			img {
				opacity: .5;
				transform: scale(1);
			}

			.lng-caption:before {
				opacity: 1;
			   transform: scale(1);
			}
		}
	}

  	img {
  		opacity: .9;
  		transition: opacity .35s,transform .35s;
  		transform: scale(1.12);
		width: 100%;
  	}

  	.lng-caption {
  		padding: 1rem 1.2rem;
  		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		&:before {
			position: absolute;
			top: 10px;
			right: 10px;
			bottom: 10px;
			left: 10px;
			border: 1px solid $black;
			content: '';
			transition: opacity .35s,transform .35s;
			transform: scale(1.1);
			opacity: 0;
		}
  	}

  	.lng-block-title {
  		font-weight: 500;
    	margin-top: 0.2rem;
    	letter-spacing: 1px;
    	font-size: 0.9rem;
    	color: $white;
    	text-transform: uppercase;
    	padding: 0 0.6rem;
    	background: rgba(0,0,0, 0.6);
  	}

  	.lng-caption-meta {
  		position: absolute;
  		background: rgba(0,0,0, 0.6);
  		bottom: 20px;
  		color: $white;
  		font-size: 0.75rem;
  		font-style: italic;
  		padding: 0.2rem 0.4rem;

  		p {
  			margin: 0;
  		}
  	}
}

